import { Button, mediaQueries, style } from '@do/walrus';
import * as React from 'react';
import styled from 'styled-components';
import HeroImage, { HeroImg } from './HeroImage';

const HeroContainer = styled.div`
  position: relative;
  overflow: hidden;
  border-radius: 3px;
  text-align: center;
  min-height: 490px;

  display: flex;
  flex-direction: column;
`;

export const HeroInner = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex: 1;
  max-width: ${style.viewports.max}px;
  padding: 0 32px;

  ${mediaQueries.mediumMin} {
    padding: 0 80px;
  }

  ${mediaQueries.largeMin} {
    font-size: 42px;
  }
`;

const HeroImageContainer = styled.div`
  display: block;
  overflow: hidden;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  box-sizing: border-box;
  margin: 0;

  ${HeroImg} {
    min-width: 100%;
    max-width: 100%;
    min-height: 100%;
    max-height: 100%;
    object-fit: cover;
    object-position: center center;
  }

  ${mediaQueries.smallMax} {
    display: none;
  }
`;

export const HeroCopy = styled.div`
  z-index: 1;
  display: flex;
  flex-direction: column;
  flex: 1;
  align-items: flex-start;
  justify-content: center;
  text-align: left;
`;

export const HeroTitle = styled.h1`
  color: white;
  font-size: 28px;
  font-weight: bold;
  word-break: break-word;
  line-height: 1.3;

  ${mediaQueries.largeMin} {
    font-size: 42px;
  }

  ${mediaQueries.xxlargeMin} {
    font-size: 52px;
  }
`;

export const HeroDescription = styled.h2`
  font-size: 15px;
  font-weight: 600;
  line-height: 1.4;
  color: white;
  margin-top: 16px;

  ${mediaQueries.smallMin} {
    font-size: 18px;
  }

  ${mediaQueries.largeMin} {
    font-size: 20px;
  }

  ${mediaQueries.xxlargeMin} {
    font-size: 22px;
  }
`;

const HeroRight = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;
  color: white;
  text-align: left;
  font-size: 21px;
  margin-left: 80px;

  ${mediaQueries.mediumMax} {
    margin-left: 0;
    width: 100%;
  }
`;

interface Props {
  src?: string;
  alt?: string;
  title: string;
  description: string;
  ctaHref?: string;
  ctaLabel?: string;
  style?: any;
  className?: string;
  children?: React.ReactNode;
}

export const Hero: React.FC<Props> = (props) => {
  const {
    src,
    alt,
    title,
    description,
    ctaHref,
    ctaLabel,
    style,
    className,
    children
  } = props;
  const altText = alt ? alt : title;

  return (
    <HeroContainer className={className} style={style}>
      <HeroInner>
        <HeroCopy>
          {title && <HeroTitle>{title}</HeroTitle>}
          {description && <HeroDescription>{description}</HeroDescription>}
          {ctaHref && ctaLabel && <Button url={ctaHref}>{ctaLabel}</Button>}
        </HeroCopy>
        {children && <HeroRight>{children}</HeroRight>}
      </HeroInner>
      {src && (
        <HeroImageContainer>
          <HeroImage staticFile={src} alt={altText} />
        </HeroImageContainer>
      )}
    </HeroContainer>
  );
};

export default Hero;
