import { Button, LoadingState, mediaQueries, style } from '@do/walrus';
import _get from 'lodash/get';
import { GetServerSideProps } from 'next';
import dynamic from 'next/dynamic';
import { useRouter } from 'next/router';
import styled from 'styled-components';
import FilteredAppListingGrid from '../components/AppListingGrid/FilteredAppListingGrid';
import HomepageHero from '../components/Hero/HomepageHero';
import MetaTags from '../components/MetaTags';
import HomepageQuery from '../graphql/queries/homepage.gql';
import { CategoryPageData, HomepageProps } from '../interfaces/Prismic';
import Main from '../layouts/Main';
import initApollo from '../utils/init-apollo';
import isInIframe from '../utils/isInIframe';
import copy from '../components/copy';
import Sidenav from '../components/Sidenav';
import { trackCategoryClick, trackUseCaseClick } from '../utils/tracking';
import { ALL_KEY } from '../utils/constants';
import { slugify } from '../utils/slug-helpers';
import _compact from 'lodash/compact';
import CategoriesQuery from '../graphql/queries/categories.gql';
import SolutionsQuery from '../graphql/queries/solutions.gql';
import { SideNavPageContent, SideNavPageWrapper } from '../styles/components';
import React from 'react';

const BrowsePopularApps = dynamic(
  () => import('../components/BrowsePopularApps')
);

interface Props {
  homepage: HomepageProps['GetCatalogHomepage'];
  preview?: boolean;
  categoriesData: any;
  solutionsData: any;
}

export const ContentHeader = styled.h2`
  font-size: 28px;

  ${mediaQueries.largeMin} {
    font-size: 38px;
  }
`;

const AllCategoriesButtonWrapper = styled.div`
  text-align: center;
  margin-top: ${style.vars.space['6']};
  margin-bottom: ${style.vars.space['6']};
`;

const NewAndNoteworthyContainer = styled.div``;

const Page: React.FC<Props> = ({
  categoriesData,
  solutionsData,
  homepage,
  preview
}) => {
  const router = useRouter();

  if (router?.isFallback) {
    return <LoadingState sammy={true} className="do-u-mt--xlarge" />;
  }

  const heroTitle = _get(homepage, 'hero_title[0].text');
  const heroDescription = _get(homepage, 'hero_text[0].text');

  const browsePopularAppsTitle = _get(homepage, 'app_cat_group_title[0].text');
  const browsePopularAppsGroup = _get(homepage, 'app_cat_group', []);

  const staffPicksAppsTitle = _get(
    homepage,
    'featured_apps_group_title[0].text'
  );
  const staffPicksAppsGroup = _get(homepage, 'featured_apps_group', []).map(
    (app) => app.featured_app
  );

  const newAndNoteworthyAppsTitle = _get(
    homepage,
    'new_and_noteworthy_apps_group_title[0].text'
  );
  const newAndNoteworthyAppsGroup = _get(
    homepage,
    'new_and_noteworthy_apps_group',
    []
  ).map((app) => app.new_and_noteworthy_app);

  let solutions;
  if (solutionsData) {
    solutions = _get(solutionsData, 'GetCatalogSolutionsPages.solutions');
  }

  const pageData: CategoryPageData = _get(
    categoriesData,
    'GetCatalogCategoriesPage'
  );

  const categoriesList = _get(pageData, 'categories');

  const categories =
    categoriesList.map((cat) => {
      const key = _get(cat, 'category') || ALL_KEY;
      return {
        key,
        is_visible_in_nav: cat.is_visible_in_nav === 'true',
        href: key ? `/category/${slugify(key)}` : '',
        text:
          key.toLowerCase() === ALL_KEY.toLowerCase() ? 'All Solutions' : key,
        description: _get(cat, 'nav_description[0].text'),
        is_new: _get(cat, 'is_new') === 'true',
        hero_text: _get(cat, 'hero_text[0].text'),
        nav_icon: _get(cat, 'nav_icon.url'),
        staff_picks: _compact([
          _get(cat, 'staff_pick_1'),
          _get(cat, 'staff_pick_2'),
          _get(cat, 'staff_pick_3'),
          _get(cat, 'staff_pick_4')
        ])
      };
    }) || [];

  return (
    <>
      <MetaTags
        title={copy.digitalOceanMarketplace}
        description="DigitalOcean Marketplace lets developers easily discover and quickly launch services, components and tools from the open source projects and companies that they love."
        url="https://marketplace.digitalocean.com"
        imageUrl="https://www.digitalocean.com/assets/media/logo-a721c4a7.png"
        imageAlt="DigitalOcean"
      />

      <Main preview={preview} isInIframe={isInIframe()}>
        <Main.FullPageWidth>
          <HomepageHero title={heroTitle} description={heroDescription} />
        </Main.FullPageWidth>
        <Main.Page>
          <SideNavPageWrapper id="find-a-solution">
            <Sidenav
              categories={categories}
              onClickCategory={trackCategoryClick}
              solutions={solutions}
              onClickSolution={trackUseCaseClick}
            />
            <SideNavPageContent>
              <>
                {newAndNoteworthyAppsGroup.length > 0 && (
                  <NewAndNoteworthyContainer>
                    <ContentHeader>{newAndNoteworthyAppsTitle}</ContentHeader>
                    <FilteredAppListingGrid
                      leftAlign
                      largeHeader
                      apps={newAndNoteworthyAppsGroup}
                    />
                  </NewAndNoteworthyContainer>
                )}

                <FilteredAppListingGrid
                  leftAlign
                  largeHeader
                  headerText={staffPicksAppsTitle}
                  apps={staffPicksAppsGroup}
                />

                <AllCategoriesButtonWrapper>
                  <Button
                    url="/category/all"
                    data-testid="all-categories-button"
                  >
                    See all categories
                  </Button>
                </AllCategoriesButtonWrapper>

                <BrowsePopularApps
                  title={browsePopularAppsTitle}
                  apps={browsePopularAppsGroup}
                />
              </>
            </SideNavPageContent>
          </SideNavPageWrapper>
        </Main.Page>
      </Main>
    </>
  );
};

export const getServerSideProps: GetServerSideProps<
  any,
  any,
  { ref: string }
> = async ({ preview = false }) => {
  const apolloClient = initApollo({});

  const { data, error } = await apolloClient.query({
    query: HomepageQuery
  });

  const { data: categoriesData, error: categoriesError } =
    await apolloClient.query({
      query: CategoriesQuery
    });

  const { data: solutionsData, error: solutionError } =
    await apolloClient.query({
      query: SolutionsQuery
    });

  if (solutionError || categoriesError || error) {
    return {
      notFound: true
    };
  }

  const homepage = _get(data, 'GetCatalogHomepage');

  return {
    props: {
      homepage,
      preview,
      categoriesData,
      solutionsData
    }
    // Next.js will attempt to re-generate the page when a request comes in, at most once every 300 seconds (5 minutes)
    // https://nextjs.org/docs/basic-features/data-fetching/incremental-static-regeneration
    // This ensures we get up to date content from Prismic without the need to rebuild the project
    // TODO: Re-evaluate in https://jira.internal.digitalocean.com/browse/MP-5432
    // revalidate: 300
  };
};

export default Page;
