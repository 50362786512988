import { mediaQueries, style } from '@do/walrus';
import dynamic from 'next/dynamic';
import * as React from 'react';
import styled from 'styled-components';
import { SearchResults } from '../Search';
import Hero, { HeroCopy, HeroInner } from '.';

const Search = dynamic(() => import('../Search'));

const MAX_WIDTH = 705;
const SEARCH_RESULTS_MAX_HEIGHT = 275;

const SearchWrapper = styled.div`
  position: absolute;
  bottom: ${style.vars.space['5']};
  left: 0;
  right: 0;
  padding: 0 ${style.vars.space['5']};
  color: ${style.colors.white};
  font-size: ${style.vars.fontSize.large};
  width: 100%;
  margin: 0 auto;

  ${mediaQueries.mediumMin} {
    width: ${MAX_WIDTH}px;
    padding: 0;
  }

  ${mediaQueries.largeMin} {
    ${SearchResults} {
      max-height: ${SEARCH_RESULTS_MAX_HEIGHT}px;
    }
  }
`;

const HomepageHeroContainer = styled.div`
  position: relative;
`;

const HomepageHeroElt = styled(Hero)`
  background: #0147ff;
  min-height: 0;
  padding-bottom: 96px;

  ${HeroCopy} {
    position: relative;
    max-width: ${MAX_WIDTH}px;
    margin: 0 auto;
    padding-top: ${style.vars.space['5']};
    padding-left: 0;
    padding-right: 0;
  }

  ${HeroInner} {
    margin: 0 auto;
  }
`;

interface Props {
  src?: string;
  alt?: string;
  title: string;
  description: string;
  ctaHref?: string;
  ctaLabel?: string;
  style?: any;
  className?: string;
  children?: React.ReactNode;
}

export const HomepageHero: React.FC<Props> = ({ children, ...props }) => {
  return (
    <HomepageHeroContainer>
      <HomepageHeroElt {...props} src="homepage-hero.png">
        {React.Children.map(children, (child) =>
          React.cloneElement(child as React.ReactElement<any>)
        )}
      </HomepageHeroElt>
      <SearchWrapper>
        <Search />
      </SearchWrapper>
    </HomepageHeroContainer>
  );
};

export default HomepageHero;
